"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignSelect = void 0;
const ui_1 = require("@adtriba/ui");
const auth0_react_1 = require("@auth0/auth0-react");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const constants_1 = require("../../../../constants");
const util_1 = require("../../../../helpers/util");
const spend_mapper_v3_service_1 = require("../../services/spend-mapper-v3.service");
const spend_mapper_v3_validations_1 = require("./spend-mapper-v3-validations");
require("./spend-mapper-v3.component.sass");
const CampaignSelect = ({ initialSelected, options, premapped, mappingId, isFallback, onSave }) => {
    const { user, getAccessTokenSilently } = auth0_react_1.useAuth0();
    const [selected, setSelected] = react_1.useState([]);
    const [loading, setLoading] = react_1.useState(null);
    const [error, setError] = react_1.useState(null);
    const { accountId, projectId } = react_router_dom_1.useParams();
    const [open, setOpen] = react_1.useState(false);
    const [confirm, setConfirm] = react_1.useState(false);
    const indexesRef = react_1.useRef(null);
    const [validations, setValidations] = react_1.useState([]);
    const updateMappings = (indexes) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setError(null);
        try {
            const token = yield getAccessTokenSilently();
            // Create a unique array of ID's
            const platformMappings = options.filter((_, index) => indexes.includes(index));
            const mappingIds = platformMappings.map((option) => option.mapping_id);
            // We don't need this FOR NOW (leaving it here)
            // const mappingSpend = platformMappings.reduce((acc, option) => acc + option.spend, 0)
            // Construct the object for the API
            const mappings = [
                {
                    ad_platform_mapping_ids: mappingIds,
                    adtriba_campaign_mapping_id: mappingId,
                },
            ];
            // Actual API call
            yield spend_mapper_v3_service_1.SpendMapperService.putCampaignMappings(token, accountId, projectId, {}, mappings);
            setSelected(indexes);
            setLoading(false);
            // Clear our cache / modal
            indexesRef.current = null;
            setOpen(false);
            setConfirm(false);
            setValidations([]);
            // Reload the campaigns table
            onSave();
        }
        catch (e) {
            setError(constants_1.ERROR.API.GENERAL);
            setLoading(false);
            util_1.logError(e);
        }
    });
    const handleOkay = (indexes) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setError(null);
        try {
            const token = yield getAccessTokenSilently();
            // Create a unique array of ID's
            const platformMappings = options.filter((_, index) => indexes.includes(index));
            const mappingIds = platformMappings.map((option) => option.mapping_id);
            // We don't need this FOR NOW (leaving it here)
            // const mappingSpend = platformMappings.reduce((acc, option) => acc + option.spend, 0)
            // Construct the object for the API
            const mappings = [
                {
                    ad_platform_mapping_ids: mappingIds,
                    adtriba_campaign_mapping_id: mappingId,
                },
            ];
            // Call the api
            const result = yield spend_mapper_v3_service_1.SpendMapperService.postCampaignMappingsValidate(token, accountId, projectId, {}, mappings);
            // Check if there are any validation error
            // If there are none - then save right away
            // otherwise cache & show a confirm
            if (result.length == 0) {
                updateMappings(indexes);
            }
            else {
                indexesRef.current = indexes;
                setValidations(result);
                setConfirm(true);
            }
        }
        catch (e) {
            setError(constants_1.ERROR.API.GENERAL);
            setLoading(false);
            util_1.logError(e);
        }
    });
    const handleDismiss = () => {
        if (!indexesRef.current)
            setOpen(false);
    };
    const handleClear = () => {
        updateMappings([]);
    };
    react_1.useEffect(() => {
        const selectedIds = (initialSelected || '').split(',');
        const indexes = [];
        options.filter((option, index) => {
            if (selectedIds.includes(option.mapping_id))
                indexes.push(index);
        });
        setSelected(indexes);
    }, [initialSelected, premapped, options]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ui_1.If, { if: premapped },
            react_1.default.createElement(ui_1.Label, { icon: "check" }, "Premapped")),
        react_1.default.createElement(ui_1.If, { if: isFallback },
            react_1.default.createElement(ui_1.View, { width: 100 },
                react_1.default.createElement("a", { href: "https://help.adtriba.com/en/articles/4507112-spend-mapper", target: "_blank", className: "cl-electric fs-400 fw-900" }, "What is this?"))),
        react_1.default.createElement(spend_mapper_v3_validations_1.Validations, { campaign: true, source: false, button: "Save", visible: confirm, validations: validations, onConfirm: () => updateMappings(indexesRef.current), onDismiss: () => setConfirm(false), title: "These campaign mappings will be overwritten" }),
        react_1.default.createElement(ui_1.View, { class: "p-relative" },
            react_1.default.createElement(ui_1.If, { if: isFallback },
                react_1.default.createElement(ui_1.View, { class: "p-absolute w-100 h-100 bg-cl-white", style: { zIndex: 10, opacity: 0.5 } })),
            react_1.default.createElement(ui_1.SelectManual, { small: true, filterable: true, open: open, buttonText: "Save", width: 500, selected: selected, placeholder: "There are no options selected", backgroundColor: "transparent", showClear: true, onClick: () => setOpen(!open), onDismiss: handleDismiss, onClear: handleClear, onOkay: handleOkay }, options.map((option, index) => {
                const { spend, campaign, mapped } = option;
                const text = [campaign, spend].filter((text) => !!text).join(' → ');
                return (react_1.default.createElement(ui_1.SelectOption, { key: index, class: mapped ? 'cl-green' : '' }, text));
            })),
            react_1.default.createElement(ui_1.If, { if: !!error },
                react_1.default.createElement(ui_1.Text, { small: true, class: "cl-red ml-500" }, error)))));
};
exports.CampaignSelect = CampaignSelect;
