"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationsService = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class IntegrationsService {
    static getGoogleRedirectAuthToken(token, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integration/create-google-redirect-setup-auth`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getIntegrations(token, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static deleteIntegrations(token, accountId, integrationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/${integrationId}`;
            const result = yield fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getIntegrationsGoogle(token, accountId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/google?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnGoogleAdsApiIntegrationParser(result);
        });
    }
    static deleteIntegrationsGoogle(token, accountId, tokenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/token/${tokenId}`;
            const result = yield fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static putIntegrationsUpdate(token, accountId, payload, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/update?${queryString}`;
            const result = yield fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(payload),
            });
            return yield util_1.returnParser(result);
        });
    }
    static postIntegrationsUpdate(token, accountId, payload, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/update?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(payload),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getIntegrationsFacebook(token, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/facebook`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static deleteIntegrationsFacebook(token, accountId, integrationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/facebook?integration_id=${integrationId}`;
            const result = yield fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static putIntegrationsFacebook(token, accountId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/integrations/facebook`;
            const result = yield fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(payload),
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.IntegrationsService = IntegrationsService;
