"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const ui_1 = require("@adtriba/ui");
const react_1 = __importStar(require("react"));
const dialog_component_1 = require("./dialog.component");
const NoAccountsDialog = ({ show, logout }) => {
    const ref = react_1.useRef(null);
    react_1.useEffect(() => {
        var _a, _b;
        if (show) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.showModal();
        }
        else {
            (_b = ref.current) === null || _b === void 0 ? void 0 : _b.close();
        }
    }, [show]);
    return (react_1.default.createElement(dialog_component_1.DialogComponent, { ref: ref },
        react_1.default.createElement(ui_1.View, { class: "p-fixed top-0 left-0", width: "100%", height: "100%", row: true, style: { zIndex: 10 } },
            react_1.default.createElement(ui_1.Panel, { width: 600, innerClass: "d-flex flex-column gap-6 pb-0", style: { padding: '1.5rem', boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.05)' } },
                react_1.default.createElement("h2", { style: {
                        fontWeight: 600,
                    } }, "No accounts found"),
                react_1.default.createElement("div", { className: "gap-4 d-flex align-items-center" },
                    react_1.default.createElement("img", { src: '/favicon.png', height: 20, alt: "Adtriba Accounts" }),
                    react_1.default.createElement("h3", { style: {
                            fontWeight: 600,
                        } }, "Welcome to Adtriba Core")),
                react_1.default.createElement("p", { className: "leading-6" },
                    "There was an error while we were trying to load your account(s).",
                    react_1.default.createElement("br", null),
                    "Please log out and try again."),
                react_1.default.createElement("p", null,
                    "If you run into any trouble again, please contact:",
                    react_1.default.createElement("br", null),
                    react_1.default.createElement("a", { href: "mailto:support@adtriba.com" },
                        react_1.default.createElement("b", { style: {
                                fontWeight: 600,
                                color: '#1CC7D0',
                            } }, "support@adtriba.com"))),
                react_1.default.createElement(ui_1.Button, { onClick: () => logout(), class: "mr-auto" },
                    react_1.default.createElement(ui_1.ButtonText, null, "Logout"))))));
};
exports.default = NoAccountsDialog;
