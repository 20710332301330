"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRedirect = exports.AppModuleRoutes = exports.NavRoutes = exports.AppRoutes = void 0;
const auth0_react_1 = require("@auth0/auth0-react");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const constants_1 = require("../../constants");
const app_context_1 = require("../../contexts/app.context");
require("./app.module.sass");
const channel_mapper_component_1 = require("./components/channel-mapper/channel-mapper.component");
const customer_journey_component_1 = require("./components/customer-journey/customer-journey.component");
const external_component_1 = require("./components/external/external.component");
const insights_component_1 = require("./components/insights/insights.component");
const integrations_component_1 = require("./components/integrations/integrations.component");
const performance_component_1 = require("./components/performance/performance.component");
const spend_mapper_v2_component_1 = require("./components/spend-mapper-v2/spend-mapper-v2.component");
const spend_mapper_v3_component_1 = require("./components/spend-mapper-v3/spend-mapper-v3.component");
const spend_mapper_component_1 = require("./components/spend-mapper/spend-mapper.component");
const tracking_component_1 = require("./components/tracking/tracking.component");
const tv_component_1 = require("./components/tv/tv.component");
class AppRoutes {
    static generate(role, features) {
        return [
            {
                path: `/accounts/:accountId/projects/:projectId/insights`,
                icon: 'opportunities',
                label: 'Insights',
                end: 'insights',
                rule: 'insights',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/reports/:reportId/performance`,
                icon: 'performance',
                label: 'Performance',
                end: 'performance',
                rule: 'performance',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                    reportId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/tracking`,
                icon: 'customer-journey',
                label: 'Tracking',
                end: 'tracking',
                rule: 'tracking',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/reports/:reportId/tv`,
                icon: 'tv',
                label: 'TV',
                end: 'tv',
                rule: 'tv',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                    reportId: true,
                },
            },
            {
                path: `/accounts/:accountId/projects/:projectId/reports/:reportId/customer-journey`,
                icon: 'dataquality',
                label: 'Customer Journey',
                end: 'customer-journey',
                rule: 'customer_journey',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                    reportId: true,
                },
            },
            {
                hidden: role == constants_1.ROLE.READER || role == constants_1.ROLE.EDITOR,
                path: `/accounts/:accountId/projects/:projectId/integrations`,
                icon: 'integrations',
                label: 'Integrations',
                end: 'integrations',
                rule: 'integrations',
                enabled: false,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                hidden: role == constants_1.ROLE.READER,
                path: `/accounts/:accountId/projects/:projectId/channel-mapper`,
                icon: 'zap',
                label: 'Channel Mapper',
                end: 'channel-mapper',
                rule: 'channel_mapper',
                enabled: false,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
            {
                hidden: role == constants_1.ROLE.READER || features.spendmapperv3,
                path: `/accounts/:accountId/spend-mapper`,
                icon: 'money',
                label: 'Spend Mapper',
                end: 'spend-mapper',
                rule: 'spend_mapper',
                enabled: true,
                required: {
                    accountId: true,
                },
            },
            {
                hidden: role == constants_1.ROLE.READER || !features.spendmapperv3,
                path: `/accounts/:accountId/projects/:projectId/spend-mapper-v3`,
                icon: 'money',
                label: 'Spend Mapper',
                end: 'spend-mapper-v3',
                rule: 'spend_mapper',
                enabled: true,
                required: {
                    accountId: true,
                    projectId: true,
                },
            },
        ];
    }
}
exports.AppRoutes = AppRoutes;
const NavRoutes = ({ component, className }) => {
    return (react_1.default.createElement("div", { className: className },
        react_1.default.createElement(react_router_dom_1.Route, { exact: true, component: component, path: [
                '/',
                '/accounts',
                '/accounts/:accountId',
                '/accounts/:accountId/projects/:projectId',
                '/accounts/:accountId/projects/:projectId/tracking',
                '/accounts/:accountId/projects/:projectId/insights',
                '/accounts/:accountId/projects/:projectId/reports/:reportId',
                '/accounts/:accountId/projects/:projectId/reports/:reportId/*',
                '/accounts/:accountId/projects/:projectId/integrations',
                '/accounts/:accountId/spend-mapper',
                '/accounts/:accountId/spend-mapper-v1',
                '/accounts/:accountId/spend-mapper-v3',
                '/accounts/:accountId/projects/:projectId/spend-mapper-v3',
                '/accounts/:accountId/projects/:projectId/channel-mapper',
                '/accounts/:accountId/projects/:projectId/external',
                '/accounts/:accountId/external',
            ] })));
};
exports.NavRoutes = NavRoutes;
const appModuleRoutes = [
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/insights',
        component: insights_component_1.InsightsComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/reports/:reportId/performance',
        component: performance_component_1.PerformanceComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/tracking',
        component: tracking_component_1.TrackingComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/reports/:reportId/customer-journey',
        component: customer_journey_component_1.CustomerJourneyComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/reports/:reportId/tv',
        component: tv_component_1.TvComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/integrations',
        restrictedRoles: constants_1.ROLE.READER || constants_1.ROLE.EDITOR,
        component: integrations_component_1.IntegrationsComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper',
        restrictedRoles: constants_1.ROLE.READER,
        component: spend_mapper_component_1.SpendMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper-v1',
        restrictedRoles: constants_1.ROLE.READER,
        component: spend_mapper_component_1.SpendMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper-v2',
        restrictedRoles: constants_1.ROLE.READER,
        component: spend_mapper_v2_component_1.SpendMapperV2Component,
    },
    {
        exact: true,
        path: '/accounts/:accountId/spend-mapper-v3',
        restrictedRoles: constants_1.ROLE.READER,
        component: spend_mapper_v3_component_1.SpendMapperV3Component,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/spend-mapper-v3',
        restrictedRoles: constants_1.ROLE.READER,
        component: spend_mapper_v3_component_1.SpendMapperV3Component,
    },
    {
        exact: true,
        path: '/accounts/:accountId/channel-mapper',
        restrictedRoles: constants_1.ROLE.READER,
        component: channel_mapper_component_1.ChannelMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/channel-mapper',
        restrictedRoles: constants_1.ROLE.READER,
        component: channel_mapper_component_1.ChannelMapperComponent,
    },
    {
        exact: true,
        path: '/accounts/:accountId/projects/:projectId/external',
        component: external_component_1.ExternalComponent,
    },
];
const AppModuleRoutes = ({ className }) => {
    const app = react_1.useContext(app_context_1.AppContext);
    return (react_1.default.createElement("div", { className: className }, appModuleRoutes.map((route, index) => {
        const { component, exact, restrictedRoles } = route;
        const path = restrictedRoles === app.role ? '/' : route.path;
        return react_1.default.createElement(react_router_dom_1.Route, { key: index, path: path, exact: exact, component: component });
    })));
};
exports.AppModuleRoutes = AppModuleRoutes;
// Handle redirects based on account, project, and report selection
const handleRedirect = (accountId, projectId, reportId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const params = react_router_dom_1.useParams();
    const app = react_1.useContext(app_context_1.AppContext);
    const { user, logout, getAccessTokenSilently: auth } = auth0_react_1.useAuth0();
    const history = react_router_dom_1.useHistory();
    const { pathname } = window.location;
    const parts = pathname.split('/');
    const last = parts[parts.length - 1];
    // If they change projects
    if (projectId != params.projectId) {
        const token = yield auth();
        const reports = (_b = (_a = app.selectedAccount) === null || _a === void 0 ? void 0 : _a.find((project) => project.id == projectId)) === null || _b === void 0 ? void 0 : _b.reports;
        const report = reports.length == 0 ? -1 : 0;
        const reportId = reports[report] ? reports[report].id : constants_1.PLACEHOLDER_UUID_FOR_URL;
        switch (last) {
            case 'performance':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance`);
                break;
            case 'tv':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv`);
                break;
            case 'customer-journey':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey`);
                break;
            case 'tracking':
                history.push(`/accounts/${accountId}/projects/${projectId}/tracking`);
                break;
            case 'insights':
                history.push(`/accounts/${accountId}/projects/${projectId}/insights`);
                break;
            case 'channel-mapper':
                history.push(`/accounts/${accountId}/projects/${projectId}/channel-mapper`);
                break;
            case 'spend-mapper':
                history.push(`/accounts/${accountId}/spend-mapper`);
                break;
            case 'spend-mapper-v2':
                history.push(`/accounts/${accountId}/spend-mapper-v2`);
                break;
            case 'spend-mapper-v3':
                history.push(`/accounts/${accountId}/projects/${projectId}/spend-mapper-v3`);
                break;
        }
    }
    // If they change reports
    if (reportId != params.reportId) {
        switch (last) {
            case 'performance':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance`);
                break;
            case 'tv':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv`);
                break;
            case 'customer-journey':
                history.push(`/accounts/${accountId}/projects/${projectId}/reports/${reportId}/customer-journey`);
                break;
            case 'tracking':
                history.push(`/accounts/${accountId}/projects/${projectId}/tracking`);
                break;
            case 'insights':
                history.push(`/accounts/${accountId}/projects/${projectId}/insights`);
                break;
            case 'channel-mapper':
                history.push(`/accounts/${accountId}/projects/${projectId}/channel-mapper`);
                break;
            case 'spend-mapper':
                history.push(`/accounts/${accountId}/spend-mapper`);
                break;
            case 'spend-mapper-v2':
                history.push(`/accounts/${accountId}/spend-mapper-v2`);
                break;
            case 'spend-mapper-v3':
                history.push(`/accounts/${accountId}/projects/${projectId}/spend-mapper-v3`);
                break;
        }
    }
});
exports.handleRedirect = handleRedirect;
