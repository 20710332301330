"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpendMapperService = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class SpendMapperService {
    static getOriginCampaigns(token, accountId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/spend_mapper/origin_campaigns?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getAdtribaCampaigns(token, accountId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/spend-mapper/adtriba_campaigns?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getAdtribaCampaignsChannel(token, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/spend-mapper/adtriba_campaigns/filter/channel`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getCampaignRecommendations(token, accountId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/spend-mapper/campaign_recommendations?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static postMappings(token, accountId, mappings) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/spend-mapper/mappings`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(mappings),
            });
            return yield util_1.returnParser(result);
        });
    }
    static deleteMappings(token, accountId, mappingId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/spend-mapper/mappings/${mappingId}`;
            const result = yield fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static postOriginCampaignStatus(token, accountId, status) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/spend-mapper/origin_campaigns/status`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(status),
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.SpendMapperService = SpendMapperService;
