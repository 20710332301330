"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ruleset = void 0;
const ui_1 = require("@adtriba/ui");
const auth0_react_1 = require("@auth0/auth0-react");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const loader_component_1 = require("../../../../components/loader/loader.component");
const notification_component_1 = require("../../../../components/notification/notification.component");
const constants_1 = require("../../../../constants");
const util_1 = require("../../../../helpers/util");
const spend_mapper_v3_service_1 = require("../../services/spend-mapper-v3.service");
const spend_mapper_v3_validations_1 = require("./spend-mapper-v3-validations");
require("./spend-mapper-v3.component.sass");
const Ruleset = ({ visible, source, channel, onClose, onMap }) => {
    const { user, getAccessTokenSilently } = auth0_react_1.useAuth0();
    const alert = react_1.useContext(ui_1.AlertContext);
    const [error, setError] = react_1.useState(null);
    const [notification, setNotification] = react_1.useState(null);
    const [loading, setLoading] = react_1.useState(null);
    const { accountId, projectId } = react_router_dom_1.useParams();
    const comparisons = [
        'EQUALS',
        'NOT_EQUALS',
        'NOT_CONTAINS',
        'CONTAINS',
        'CONTAINS_IGNORE_CASE',
        'NOT_CONTAINS_IGNORE_CASE',
        'REGEX',
        'NOT_REGEX',
    ];
    const [groups, setGroups] = react_1.useState([]);
    const [options, setOptions] = react_1.useState([]);
    const [validations, setValidations] = react_1.useState([]);
    const [confirm, setConfirm] = react_1.useState(false);
    const [warn, setWarn] = react_1.useState(false);
    const groupsRef = react_1.useRef(null);
    const [isNew, setIsNew] = react_1.useState(false);
    const sort = (a, b) => a.localeCompare(b);
    const getSourceRulesetOptions = () => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        try {
            const token = yield getAccessTokenSilently();
            const options = [];
            // Filter values for the source mapping modal
            // These values populate the dropdown options for the
            // filter component options (right hand side drop down)
            const { datasource_platform_names, sub_platform_names } = yield spend_mapper_v3_service_1.SpendMapperService.getFilterValues(token, accountId, projectId, {
                project_id: projectId,
            });
            // If there are data sources
            if (datasource_platform_names.length) {
                options.push({
                    label: 'Data Source',
                    value: 'datasource_platform_name',
                    disableComparison: null,
                    valuesFor: ['EQUALS', 'NOT_EQUALS'],
                    values: datasource_platform_names.sort(sort).map((name) => ({ label: name, value: name })),
                });
            }
            // If there are sub platforms
            if (sub_platform_names.length) {
                options.push({
                    label: 'Sub Platform',
                    value: 'sub_platform_name',
                    disableComparison: null,
                    valuesFor: ['EQUALS', 'NOT_EQUALS'],
                    values: sub_platform_names.sort(sort).map((name) => ({ label: name, value: name })),
                });
            }
            // Always present
            options.push({
                label: 'Campaign',
                value: 'campaign',
                disableComparison: null,
                valuesFor: [],
                values: null,
            });
            // Set the options with the values passed from the API
            setOptions(options);
        }
        catch (e) {
            util_1.logError(e);
            if (e == 204) {
                setGroups([{ logic: null, rows: [] }]);
                setIsNew(true);
            }
            else {
                setError(constants_1.ERROR.API.GENERAL);
            }
        }
    });
    const getSourceRulesetRules = (validate) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        try {
            const token = yield getAccessTokenSilently();
            // Get the rulesets for this source
            // This shouldn't return a 204 when empty
            const result = yield spend_mapper_v3_service_1.SpendMapperService.getRulesets(token, accountId, projectId, {
                project_id: projectId,
                channel,
                source,
            });
            const groups = util_1.formatIncomingRulesets(result.rules, options);
            setIsNew(false);
            setGroups(groups);
            if (validate)
                validateSourceRulesetGroups({ confirm: false, warn: false, groups, options });
        }
        catch (e) {
            util_1.logError(e);
            if (e == 204) {
                setGroups([{ logic: null, rows: [] }]);
                setIsNew(true);
            }
            else {
                setError(constants_1.ERROR.API.GENERAL);
            }
        }
    });
    const validateSourceRulesetGroups = ({ confirm, warn, groups, options }) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        // This checks to see if dropdowns are selected
        let emptyValues = false;
        groups.map((group) => {
            if (!group.rows)
                return;
            group.rows.map(({ option, comparison, value }) => {
                if (!options)
                    return;
                if (!options[option])
                    return;
                const selectedOption = options[option];
                const selectedOptionHasDropddown = !!selectedOption.values && selectedOption.valuesFor.includes(comparison);
                const valueIsString = typeof value == 'string';
                if (selectedOptionHasDropddown && !value && valueIsString)
                    emptyValues = true;
            });
        });
        // Turn this into a non blocking operation for now
        if (emptyValues)
            return setError('Please select a valid option.');
        try {
            const token = yield getAccessTokenSilently();
            const rules = util_1.formatOutgoingRulesets(groups, options);
            const result = yield spend_mapper_v3_service_1.SpendMapperService.postRulesetsValidate(token, accountId, projectId, {
                project_id: projectId,
                channel,
                source,
            }, rules);
            const valid = result.length == 0;
            // Only show the confirm & warn dialogs
            // if either have validation errors (!valid)
            setValidations(result || []);
            setConfirm(confirm ? !valid : false);
            setWarn(warn ? !valid : false);
            // If there are no validations
            // and if they are confirming a save
            // Then go straight to update & don't show modal
            if (valid && confirm)
                updateSourceRuleset(groupsRef.current, options);
        }
        catch (e) {
            setError(constants_1.ERROR.API.GENERAL);
            util_1.logError(e);
        }
    });
    const updateSourceRuleset = (groups, options) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        try {
            const token = yield getAccessTokenSilently();
            const rulesetHasEmptyRules = util_1.hasEmptyRules(groups);
            const rulesetFilterGroupsAreEmpty = util_1.filterGroupsAreEmpty(groups);
            const rules = util_1.formatOutgoingRulesets(groups, options);
            // If the groups are all empty & it's not new
            // Then delete the mapping
            if (rulesetFilterGroupsAreEmpty && !isNew) {
                setLoading(true);
                yield spend_mapper_v3_service_1.SpendMapperService.deleteRulesets(token, accountId, projectId, {
                    project_id: projectId,
                    channel,
                    source,
                });
            }
            else {
                // Otherwise - if it has any empty rulesets
                // then block the user, other:
                // if there are valid rulesets,
                // then save regardless of newness
                if (rulesetHasEmptyRules) {
                    setError('Rulesets cannot be empty');
                    setWarn(false);
                    setConfirm(false);
                    return;
                }
                else {
                    setLoading(true);
                    yield spend_mapper_v3_service_1.SpendMapperService.putRulesets(token, accountId, projectId, {
                        project_id: projectId,
                        channel,
                        source,
                    }, rules);
                }
            }
            // Update the state
            setGroups(groups);
            setLoading(false);
            setWarn(false);
            setConfirm(false);
            // Call the parent method & close the modal
            onMap();
        }
        catch (e) {
            setLoading(false);
            setError(constants_1.ERROR.API.GENERAL);
            util_1.logError(e);
        }
    });
    // When it's visible, then get the options
    react_1.useEffect(() => {
        if (visible) {
            getSourceRulesetOptions();
        }
        else {
            setOptions([]);
            setGroups([{ logic: null, rows: [] }]);
        }
    }, [visible]);
    // When there are options
    // then get the actual rules
    react_1.useEffect(() => {
        if (options.length > 0) {
            getSourceRulesetRules(true);
            // The options need to be updated first before the rules
            // For now this little hack is the best way
            // But need to find a better solution
            util_1.waitForState(() => getSourceRulesetRules(false));
        }
    }, [options]);
    // Only display this if it's not null
    if (!visible)
        return null;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(spend_mapper_v3_validations_1.Validations, { campaign: false, source: true, button: "Okay", visible: warn, validations: validations, onConfirm: () => setWarn(false), onDismiss: () => setWarn(false), title: "This ruleset conflicts with these source rulesets" }),
        react_1.default.createElement(spend_mapper_v3_validations_1.Validations, { campaign: false, source: true, button: "Save", visible: confirm, validations: validations, onConfirm: () => updateSourceRuleset(groupsRef.current, options), onDismiss: () => setConfirm(false), title: "Are you sure you want to overwrite the following source rulesets?" }),
        react_1.default.createElement(ui_1.Modal, { disableBackgroundDismiss: true, width: "900px", height: "auto", position: constants_1.POSITION.CENTER, borderRadius: 20, onDismiss: onClose, footer: null, header: react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ui_1.Label, { dark: true, icon: "map", class: "mr-900" }, "Source"),
                react_1.default.createElement("a", { href: "https://help.adtriba.com/en/collections/2825246-adtriba-core", target: "_blank" },
                    react_1.default.createElement(ui_1.Icon, { icon: "info", color: constants_1.COLOR.COLOR_GRAY_500, size: 20 }))), icon: null, title: source, showClose: true },
            react_1.default.createElement(loader_component_1.LoaderComponent, { loading: loading }),
            react_1.default.createElement(ui_1.View, { class: "p-100" },
                react_1.default.createElement(notification_component_1.NotificationComponent, { notification: notification, error: error })),
            react_1.default.createElement(ui_1.If, { if: validations.length != 0 },
                react_1.default.createElement(ui_1.View, { class: "p-100" },
                    react_1.default.createElement(ui_1.Notification, { warning: true, class: "mb-100" },
                        react_1.default.createElement(ui_1.NotificationText, null, "This rule overlaps with other rulesets."),
                        react_1.default.createElement(ui_1.NotificationIcon, { icon: "info", onClick: () => validateSourceRulesetGroups({ confirm: false, warn: true, groups, options }) })))),
            react_1.default.createElement(ui_1.Filter, { maxHeight: window.innerHeight - 200, minHeight: window.innerHeight - 200, small: false, footer: react_1.default.createElement(ui_1.Button, { class: "mr-auto", onClick: onClose },
                    react_1.default.createElement(ui_1.ButtonText, null, "Close")), footerContainerStyles: { padding: 10 }, groupContainerRulesStyles: { padding: 10 }, groupContainerToolbarStyles: { padding: 10, paddingTop: 0 }, preSelect: true, showGroupHeader: true, noLogic: true, showLogic: true, groups: groups, comparisons: comparisons, options: options, onUpdate: (groups) => {
                    setLoading(false);
                    setError(null);
                    setNotification(null);
                }, onChange: (groups) => {
                    // Cache the new groups
                    groupsRef.current = groups;
                    // Ask the user to confirm their choice
                    // which will use the cache from above
                    validateSourceRulesetGroups({ confirm: true, warn: false, groups, options });
                } }))));
};
exports.Ruleset = Ruleset;
