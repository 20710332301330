"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageService = void 0;
class StorageService {
    static setStorage(name, val) {
        localStorage.setItem(name, val);
    }
    static getStorage(name) {
        return localStorage.getItem(name);
    }
    static deleteStorage(name) {
        localStorage.removeItem(name);
    }
}
exports.StorageService = StorageService;
