"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceService = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class PerformanceService {
    static getFilter(token, accountId, projectId, reportId, level, // campaign, channel, source
    customerType) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = !!customerType
                ? `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/filter/${level}?customer_type=${customerType}`
                : `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/filter/${level}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getComparisonTable(token, accountId, projectId, reportId, level, filterOptions, queryParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/comparison-table/${level}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getLastClickValidation(token, accountId, projectId, reportId, filterOptions, queryParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/last-click-validation?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getTable(token, accountId, projectId, reportId, level, // campaign, channel, source
    filterOptions, queryParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/table/${level}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getTimeline(token, accountId, projectId, reportId, type, // roas, spend
    filterOptions, queryParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/timeline/${type}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getTotals(token, accountId, projectId, reportId, filterOptions, queryParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/performance/totals?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.PerformanceService = PerformanceService;
