"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TvService = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class TvService {
    static getAiringCombinationPerformanceTable(token, accountId, projectId, reportId, queryParameters, filterOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/airing-combination-performance/table?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getAiringCombinationPerformanceTimeline(token, accountId, projectId, reportId, type, queryParameters, filterOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/airing-combination-performance/timeline/${type}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getFilter(token, accountId, projectId, reportId, type // flight, genre-after, genre-before, program-after, program-before, spot-duration, station, timeslot
    ) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/filter/${type}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getFlightPerformance(token, accountId, projectId, reportId, type, // cpa, cpv
    queryParameters, filterOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/flight-performance/${type}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getPerformanceTable(token, accountId, projectId, reportId, level, // flight, station, timeslot
    queryParameters, filterOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/performance/table/${level}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getStationPerformance(token, accountId, projectId, reportId, type, // cpa, cpv
    queryParameters, filterOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/station-performance/${type}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getTimeslotPerformance(token, accountId, projectId, reportId, type, // cpa, cpv
    queryParameters, filterOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/timeslot-performance/${type}?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getTotals(token, accountId, projectId, reportId, queryParameters, filterOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryParameters);
            const url = `${constants_1.API_HOST_V2}/accounts/${accountId}/projects/${projectId}/reports/${reportId}/tv/totals?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filterOptions),
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.TvService = TvService;
