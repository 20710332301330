"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CZ_APP_KEY = exports.API_HOST = exports.API_HOST_V4 = exports.API_HOST_V3 = exports.API_HOST_V2 = exports.AUTH0_AUDIENCE = exports.AUTH0_CLIENTID = exports.AUTH0_DOMAIN = exports.SENTRY_DSN = exports.NODE_ENV = void 0;
exports.NODE_ENV = 'development';
exports.SENTRY_DSN = 'https://999e267274714102a3f42c3578b3eb1e@o38720.ingest.sentry.io/5642587';
exports.AUTH0_DOMAIN = 'adtribagmbh-dev.eu.auth0.com';
exports.AUTH0_CLIENTID = 'NzgQ2b7bfEcO007cB1tvhh7zEeK45o4u';
exports.AUTH0_AUDIENCE = 'https://api-dev.adtriba.io';
exports.API_HOST_V2 = 'https://api-dev.adtriba.io/v2';
exports.API_HOST_V3 = 'https://api-dev.adtriba.io/v3';
exports.API_HOST_V4 = 'https://api-dev.adtriba.io/v4';
exports.API_HOST = 'https://api-dev.adtriba.io';
exports.CZ_APP_KEY = '1!0SJbxEJw46VV4TRKEZijA0tMmUYM5ta2tBbCJpsViFwt88E';
