"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const auth0_react_1 = require("@auth0/auth0-react");
const react_1 = require("react");
const user_service_1 = require("../modules/app/services/user.service");
const useVerifyEmail = () => {
    const { getAccessTokenSilently: auth, logout } = auth0_react_1.useAuth0();
    const [error, setError] = react_1.useState({});
    const [loading, setLoading] = react_1.useState(false);
    const verifyUserEmail = (auth, onSuccess, onError, onLoading) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const token = yield auth();
            onLoading(true);
            const result = yield user_service_1.UserService.postVerificationEmail(token);
            onLoading(false);
            return onSuccess(result);
        }
        catch (error) {
            console.error(error);
            return onError(error);
        }
        finally {
            setLoading(false);
        }
    });
    const handleLogout = () => {
        logout();
    };
    const verifyEmail = () => __awaiter(void 0, void 0, void 0, function* () {
        verifyUserEmail(auth, handleLogout, setError, setLoading);
    });
    return {
        error,
        loading,
        verifyEmail,
    };
};
exports.default = useVerifyEmail;
