"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceRuleset = void 0;
const ui_1 = require("@adtriba/ui");
const react_1 = __importStar(require("react"));
const constants_1 = require("../../../../constants");
const spend_mapper_v3_ruleset_1 = require("./spend-mapper-v3-ruleset");
require("./spend-mapper-v3.component.sass");
const SourceRuleset = (props) => {
    const { mapped } = props;
    const [modal, setModal] = react_1.useState(false);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(spend_mapper_v3_ruleset_1.Ruleset, { visible: modal, channel: props.channel, source: props.source, onMap: () => {
                setModal(false);
                props.onMap();
            }, onClose: () => setModal(false) }),
        react_1.default.createElement(ui_1.View, { row: true, justify: "flex-start" },
            mapped && (react_1.default.createElement(ui_1.Label, { solidElectric: true, class: "mr-500", icon: "check", style: { width: 50 } }, "Mapped")),
            !mapped && (react_1.default.createElement(ui_1.Tooltip, { text: "This source needs to be mapped first.", position: constants_1.POSITION.TOP },
                react_1.default.createElement(ui_1.Label, { red: true, class: "mr-500", style: { width: 80 } }, "Mapping Required"))),
            react_1.default.createElement(ui_1.Flexer, null),
            react_1.default.createElement(ui_1.Button, { xSmall: true, onClick: () => setModal(true), light: true },
                react_1.default.createElement(ui_1.ButtonIcon, { icon: "pen" }),
                react_1.default.createElement(ui_1.ButtonText, null, "EDIT")))));
};
exports.SourceRuleset = SourceRuleset;
