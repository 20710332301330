"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelMapperV3Service = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class ChannelMapperV3Service {
    static put(token, accountId, mappings) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/channel-mapper`;
            const result = yield fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(mappings),
            });
            return yield util_1.returnParser(result);
        });
    }
    static preview(token, accountId, mappings, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/channel-mapper/preview?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(mappings),
            });
            return yield util_1.returnParser(result);
        });
    }
    static verify(token, accountId, mappings) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/channel-mapper/verify`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(mappings),
            });
            return yield util_1.returnParser(result);
        });
    }
    static get(token, accountId, projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/channel-mapper`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static delete(token, accountId, projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/channel-mapper`;
            const result = yield fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getCopy(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/channel-mapper/copy?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.ChannelMapperV3Service = ChannelMapperV3Service;
