"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingService = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class TrackingService {
    static getConversionsTable(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/table?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getConversionsTableDownload(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/table/download?${queryString}`;
            return yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
        });
    }
    static getConversionsTimeline(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/timeline?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getConversionsTypes(token, accountId, projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/conversions/types`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getImpressionsPublisher(token, accountId, projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/impressions/publisher`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getImpressionsTimeline(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/impressions/timeline?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getMicroConversionCountEvents(token, accountId, projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/micro-conversion-count/events`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getMicroConversionCountTimeline(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/micro-conversion-count/timeline?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getPageviewsTimeline(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/projects/${projectId}/tracking/pageviews/timeline?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.TrackingService = TrackingService;
