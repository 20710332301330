"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateDialog = void 0;
const ui_1 = require("@adtriba/ui");
const react_1 = __importStar(require("react"));
const dialog_component_1 = require("../dialogs/dialog.component");
const util_1 = require("../../helpers/util");
require("./updatemodal.component.sass");
exports.UpdateDialog = react_1.forwardRef(({ handleUpdate }, ref) => {
    const handleClose = () => ref.current.close();
    return (react_1.default.createElement(dialog_component_1.DialogComponent, { ref: ref, className: util_1.classNames({
            'update-modal': true,
        }) },
        react_1.default.createElement("div", { className: "update-modal-wrapper" },
            react_1.default.createElement("div", { className: "modal-content" },
                react_1.default.createElement("p", null, "A new version is available."),
                react_1.default.createElement("p", null, "Please save your work, and click to update and reload Adtriba Core.")),
            react_1.default.createElement("div", { className: "modal-buttons" },
                react_1.default.createElement("span", { "data-update-button": "true" },
                    react_1.default.createElement(ui_1.Button, { onClick: handleUpdate, class: util_1.classNames({
                            'modal-button': true,
                            'modal-button__primary': true,
                        }) }, "Update")),
                react_1.default.createElement("span", { "data-update-later": "true" },
                    react_1.default.createElement(ui_1.Button, { onClick: handleClose, class: util_1.classNames({
                            'modal-button': true,
                            'modal-button__light': true,
                        }), light: true }, "Later"))))));
});
