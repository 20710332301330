"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpendMapperCacheService = exports.SpendMapperService = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class SpendMapperService {
    static postCampaignMappingsValidate(token, accountId, projectId, queryStringParameters, campaigns) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/mappings/validate?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(campaigns),
            });
            return yield util_1.returnParser(result);
        });
    }
    static putCampaignMappings(token, accountId, projectId, queryStringParameters, campaigns) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/mappings?${queryString}`;
            const result = yield fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(campaigns),
            });
            return yield util_1.returnParser(result);
        });
    }
    static getDataCheck(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/data/check?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getCampaignTable(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaign-table?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getCampaignsAdPlatform(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/ad-platform?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getCampaignsUnmappedSpend(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/unmapped-spend${queryString && '?' + queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getChannelTable(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/channel-table?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getFilterValues(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/filter-values?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getRulesets(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static putRulesets(token, accountId, projectId, queryStringParameters, rules) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets?${queryString}`;
            const result = yield fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(rules),
            });
            return yield util_1.returnParser(result);
        });
    }
    static postRulesetsValidate(token, accountId, projectId, queryStringParameters, rules) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets/validate?${queryString}`;
            const result = yield fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(rules),
            });
            return yield util_1.returnParser(result);
        });
    }
    static deleteRulesets(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/rulesets?${queryString}`;
            const result = yield fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getSourceTable(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/source-table?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static getUnmappedSourceTable(token, accountId, projectId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST_V3}/accounts/${accountId}/projects/${projectId}/spend-mapper/campaigns/unmapped-spend/table?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.SpendMapperService = SpendMapperService;
exports.SpendMapperCacheService = {
    init: () => (window[constants_1.ADTRIBA_SM3_CACHE] = { sources: [], campaigns: [] }),
    checkSource: (source) => window[constants_1.ADTRIBA_SM3_CACHE].sources.includes(source),
    cacheSource: (source) => {
        const cache = Array.from(window[constants_1.ADTRIBA_SM3_CACHE].sources);
        cache.push(source);
        window[constants_1.ADTRIBA_SM3_CACHE].sources = cache;
    },
    checkCampaign: (campaign) => window[constants_1.ADTRIBA_SM3_CACHE].campaigns.includes(campaign),
    cacheCampaigns: (raw) => {
        // Always use the existing set
        const cache = new Set(window[constants_1.ADTRIBA_SM3_CACHE].campaigns);
        // Go through the comma delimited list
        raw.map((campaign) => campaign.map((id) => cache.add(id)));
        // Resave the cache
        window[constants_1.ADTRIBA_SM3_CACHE].campaigns = Array.from(cache);
    },
};
