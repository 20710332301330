"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountService = void 0;
const project_service_1 = require("./project.service");
const report_service_1 = require("./report.service");
class AccountService {
    static getAccountStructure(token, accountId) {
        return __awaiter(this, void 0, void 0, function* () {
            // Get the list of projects
            const projects = yield project_service_1.ProjectService.get(token, accountId);
            // Append the list children reports to each project
            const reportPromises = projects.map((project) => __awaiter(this, void 0, void 0, function* () {
                try {
                    project.reports = yield report_service_1.ReportService.get(token, accountId, project.id);
                    return project; // Return the project with reports
                }
                catch (error) {
                    console.error(`Error fetching reports for project ${project.id}:`, error);
                    project.reports = [];
                    return project; // Return the project even if an error occurs
                }
            }));
            return Promise.all(reportPromises);
        });
    }
}
exports.AccountService = AccountService;
