"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarComponent = void 0;
const ui_1 = require("@adtriba/ui");
const auth0_react_1 = require("@auth0/auth0-react");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const icon_component_1 = require("../../../../components/icon/icon.component");
const constants_1 = require("../../../../constants");
const app_context_1 = require("../../../../contexts/app.context");
const util_1 = require("../../../../helpers/util");
const app_routes_1 = require("../../app.routes");
const external_component_1 = require("../external/external.component");
require("./toolbar.component.sass");
const ToolbarComponent = (props) => {
    const { getAccessTokenSilently } = auth0_react_1.useAuth0();
    const app = react_1.useContext(app_context_1.AppContext);
    const [routes, setRoutes] = react_1.useState([]);
    const history = react_router_dom_1.useHistory();
    const { accountId } = react_router_dom_1.useParams();
    const [role, setRole] = react_1.useState(null);
    const [rules, setRules] = react_1.useState([]);
    const [loading, setLoading] = react_1.useState(false);
    const [loadingButton, setLoadingButton] = react_1.useState(null);
    const [sidebarOpen, setSidebarOpen] = react_1.useState(false);
    const [sidebarDisabled, setSidebarDisabled] = react_1.useState(true);
    // Always use the UUID's from the Context object
    // Project & report ID's might not always be available
    // The placeholder serves as filler to ensure proper URL structure
    // Within each component we will check before making API calls
    // So that we don't have to fill the URL with something like "none"
    const handleRedirect = (route, enabled) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { required } = route;
            const projectId = app.selectedProjectId;
            const reportId = app.selectedReportId;
            // Don't navigate it's not enabled
            if (!enabled)
                return;
            // If the report is required, but we have none
            // Only start the loading if there is going to be an async call
            if (required.reportId && !reportId) {
                setLoading(true);
                setLoadingButton(route.path);
            }
            // Stop the loading
            setLoading(false);
            setLoadingButton(null);
            // Navigate there
            util_1.waitForState(() => {
                history.push(route.path
                    .replace(':accountId', app.selectedAccountId)
                    .replace(':projectId', projectId)
                    .replace(':reportId', app.selectedReportId));
            }, 200);
        }
        catch (e) {
            setLoading(false);
            setLoadingButton(null);
            util_1.logError('toolbar handleredirect', e);
        }
    });
    // This is only used to set the role
    // And then to set iframes from the project API
    react_1.useEffect(() => {
        var _a;
        if (!app.accountsList.length)
            return;
        const account = app.accountsList.find((account) => account.id === app.selectedAccountId);
        const projectId = app.selectedProjectId;
        if (!app.selectedAccountId)
            return;
        if (!app.selectedProjectId)
            return;
        // Get our role
        const role = (account === null || account === void 0 ? void 0 : account.products.length)
            ? (_a = account === null || account === void 0 ? void 0 : account.products.find((product) => product.product == constants_1.PRODUCT.CORE).roles.find((project) => project.entity_id === projectId)) === null || _a === void 0 ? void 0 : _a.role
            : null;
        // Update our role state
        setRole(account.is_owner ? constants_1.ROLE.ADMIN : role);
        // Update our app state
        util_1.waitForState(() => {
            app.setApp(Object.assign(Object.assign({}, app), { role: account.is_owner ? constants_1.ROLE.ADMIN : role }));
        }, 200);
        // Reader can not see additional routes
        // if (role == ROLE.READER) return
        if (!app.selectedProjectId)
            return;
        if (!app.selectedAccount)
            return;
        // Save our rules at project level
        const project = app.selectedAccount.find((project) => project.id === app.selectedProjectId);
        if (project) {
            setRules(project.menu_items || []);
        }
        else {
            setRules([]);
        }
        // (Otherwise all good)
        if (!project.iframes || project.iframes.length == 0) {
            setRoutes([]);
        }
        else {
            setRoutes(project.iframes.map((iframe) => {
                return {
                    path: `/accounts/${accountId}/projects/${project.id}/external?url=${iframe.url}`,
                    icon: iframe.icon,
                    label: iframe.label,
                    component: external_component_1.ExternalComponent,
                };
            }));
        }
    }, [accountId, app.selectedProjectId]);
    const handleMouseEnter = () => {
        setSidebarDisabled(false);
        setSidebarOpen(true);
    };
    const handleMouseLeave = () => {
        setSidebarDisabled(true);
        setSidebarOpen(false);
    };
    return (react_1.default.createElement("div", { className: util_1.classNames({
            ['toolbar-component']: true,
            ['sidebar-open']: sidebarOpen,
        }), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onMouseMove: handleMouseEnter, "data-disabled": sidebarDisabled },
        react_1.default.createElement(ui_1.View, { class: "toolbar-component__container", displayFlex: true, direction: "column", justify: "space-between", flex: 1, align: "initial" },
            react_1.default.createElement(ui_1.View, null,
                app_routes_1.AppRoutes.generate(role, {
                    spendmapperv3: true,
                }).map((route, index) => {
                    if (route.hidden)
                        return null;
                    const buttonIsLoading = loading && loadingButton == route.path;
                    const pathPart = window.location.pathname.split('/');
                    const lastPart = pathPart[pathPart.length - 1] ? pathPart[pathPart.length - 1] : '';
                    const isActive = lastPart == (route.end || buttonIsLoading);
                    const color = isActive ? constants_1.COLORS.TOOLBAR.ACTIVE : constants_1.COLORS.TOOLBAR.INACTIVE;
                    const enabled = rules.length == 0 ? route.enabled : util_1.getEnabledRule(route.rule, rules);
                    return (react_1.default.createElement(ui_1.View, { key: route.path, class: "toolbar-menu", displayFlex: true, align: "center" },
                        react_1.default.createElement("div", { className: util_1.classNames({
                                ['toolbar-menu__item']: true,
                                ['buttonize']: true,
                                ['active']: isActive,
                            }), onClick: () => {
                                handleRedirect(route, enabled);
                            }, "data-toolbar-button": route.rule, "data-disabled": !!!enabled },
                            react_1.default.createElement(ui_1.View, { class: "menu-item__inner-wrapper", displayFlex: true, align: "center", justify: "center", flex: "initial" },
                                react_1.default.createElement(ui_1.Icon, { icon: route.icon, size: 24, color: color, style: { opacity: enabled ? 1 : 0.3, zIndex: 0, position: 'relative' } }),
                                react_1.default.createElement("p", null, route.label)))));
                }),
                routes.map((route) => {
                    const color = util_1.routeIsActive(route.path) ? constants_1.COLORS.TOOLBAR.ACTIVE : constants_1.COLORS.TOOLBAR.INACTIVE;
                    return (react_1.default.createElement(ui_1.View, { key: route.path, class: "toolbar-menu", displayFlex: true, align: "center", flex: "initial" },
                        react_1.default.createElement(ui_1.View, { class: util_1.classNames({
                                ['toolbar-menu__item']: true,
                                ['buttonize']: true,
                            }), onClick: () => {
                                history.push(route.path);
                            }, "data-toolbar-button": route.label },
                            react_1.default.createElement(ui_1.View, { class: "menu-item__inner-wrapper", displayFlex: true, align: "center", justify: "center", flex: "initial" },
                                react_1.default.createElement(ui_1.Icon, { icon: route.icon, size: 24, color: color }),
                                react_1.default.createElement("p", null, route.label !== '' ? route.label : 'Looker Dashboard')))));
                })),
            react_1.default.createElement(ui_1.View, { class: "toolbar-menu", displayFlex: true, align: "center", flex: "initial" },
                react_1.default.createElement("a", { href: "https://www.adtriba.com", target: "_blank", className: "toolbar-menu__item p-relative" },
                    react_1.default.createElement(ui_1.View, { class: "menu-item__inner-wrapper", displayFlex: true, align: "center", justify: "center", flex: "initial" },
                        react_1.default.createElement(icon_component_1.IconComponent, { icon: "info", size: 24, color: constants_1.COLORS.TOOLBAR.INACTIVE }),
                        react_1.default.createElement("p", null, "Help & support")))))));
};
exports.ToolbarComponent = ToolbarComponent;
