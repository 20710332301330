"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsightsService = void 0;
const constants_1 = require("../../../constants");
const util_1 = require("../../../helpers/util");
class InsightsService {
    static get(token, accountId, queryStringParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = util_1.createURLQueryString(queryStringParameters);
            const url = `${constants_1.API_HOST}/accounts/${accountId}/insights/results?${queryString}`;
            const result = yield fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return yield util_1.returnParser(result);
        });
    }
    static patch(token, accountId, insightUpdateRequests) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${constants_1.API_HOST}/accounts/${accountId}/insights/results`;
            const result = yield fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(insightUpdateRequests),
            });
            return yield util_1.returnParser(result);
        });
    }
}
exports.InsightsService = InsightsService;
